import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Link, Theme } from '@mui/material';

const useStyle = makeStyles((theme: Theme) => {
  return {
    linksWrapper: {
      gap: theme.spacing(2.5),
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      color: 'rgba(255,255,255,0.3)',
      '& a': {
        color: 'rgba(255,255,255,0.3)',
        textDecoration: 'none',
        borderBottom: '2px solid',
      },
      '& a:hover': {
        opacity: 1,
        color: 'rgba(255,255,255,1)',
      },
    },
  };
});

const Footer = () => {
  const classes = useStyle();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      mt={2.5}
      flexWrap="wrap"
      pb={5}
    >
      <Box className={classes.linksWrapper}>
        <div>
          by{' '}
          <Link
            target="new"
            sx={{ whiteSpace: 'nowrap' }}
            href="https://twitter.com/p0pps"
          >
            p0pps
          </Link>{' '}
          for{' '}
          <Link
            target="new"
            sx={{ whiteSpace: 'nowrap', paddingInline: 0 }}
            href="https://mustardlabs.io"
          >
            Mustard Labs
          </Link>
        </div>
      </Box>
    </Box>
  );
};

export default Footer;
