import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { useEthers } from '@usedapp/core';
import { Contract, ethers } from 'ethers';
import {
  candiesShopABI,
  CANDIES_ADDRESS,
  CANDIES_SHOP_ADDRESS,
  regularDollarsABI,
  REGULAR_DOLLARS_ADDRESS,
} from 'web3/config';
import { getErrorMessageMetamask } from 'utils/helper';

const MintReg = ({ available, setAvailable }: any) => {
  const { library, account } = useEthers();
  const [price, setPrice] = useState(0);

  const getAvailable = () => {
    const contractInstance = new Contract(
      CANDIES_SHOP_ADDRESS,
      candiesShopABI,
      library
    );
    contractInstance
      .availableREG()
      .then((data: any) => {
        console.log(data);
        setAvailable(Number(data));
      })
      .catch((e: any) => console.log(e));
  };

  const mintWithReg = () => {
    const contractInstance = new Contract(
      REGULAR_DOLLARS_ADDRESS,
      regularDollarsABI,
      library?.getSigner()
    );

    contractInstance
      .transferAndCall(CANDIES_SHOP_ADDRESS, price)
      .catch((e: any) => alert(getErrorMessageMetamask(e)));
  };

  const getPrice = () => {
    const contractInstance = new Contract(
      CANDIES_SHOP_ADDRESS,
      candiesShopABI,
      library
    );
    contractInstance.getPriceREG().then((data: any) => setPrice(data));
  };

  useEffect(() => {
    getPrice();
    getAvailable();
  }, [library]);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div>Available: {available}</div>
      <div>Price: {price && ethers.utils.formatEther(price)} REG</div>
      <Button
        variant="contained"
        onClick={() => {
          mintWithReg();
        }}
        disabled={Number(available) === 0}
      >
        mint
      </Button>
    </Box>
  );
};

export default MintReg;
