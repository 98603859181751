import React, { useState } from 'react';
import { Box, SwipeableDrawer, useMediaQuery, useTheme } from '@mui/material';
import ToggleDarkModeButton from './ToggleDarkModeButton';
import ConnectButton from './ConnectButton';
import { sort0xAddress } from 'utils/helper';
import NavMenu from './NavMenu';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { RegDollarsContext } from 'context/RegDollarsContext';

const HomeHeader: React.FC<{
  account: string | null | undefined;
  activateBrowserWallet: () => void;
  mintData: any;
  availableEth: any;
  availableReg: any;
  auctionPaused: any;
}> = ({
  account,
  activateBrowserWallet,
  mintData,
  availableEth,
  availableReg,
  auctionPaused,
}) => {
  const classes = useStyles();
  // const { balance } = useContext(RegDollarsContext);

  const navigate = useNavigate();
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up(800));
  const isMobile = useMediaQuery(theme.breakpoints.down(450));
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <Box className={classes.headerMenu}>
      <SwipeableDrawer
        anchor="left"
        open={showMobileMenu}
        onClose={() => setShowMobileMenu(false)}
        onOpen={() => setShowMobileMenu(true)}
        sx={{
          '& a': {
            textDecoration: 'none',
          },
          '& > div': {
            padding: '0 40px',
          },
          '& div': {
            color: 'white',
            textDecoration: 'none',
          },
          '& > div > div': {
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <NavMenu
          isMobileMenu={true}
          showMobileMenu={showMobileMenu}
          setShowMobileMenu={setShowMobileMenu}
          availableEth={availableEth}
          availableReg={availableReg}
          auctionPaused={auctionPaused}
        />
      </SwipeableDrawer>
      <Box
        style={{
          opacity: 0.4,
          fontSize: '16px',
          paddingBottom: '3px',
          width: '100%',
          maxWidth: '145px',
        }}
      >
        {mintData && mintData.totalCount && (
          <>
            {mintData.totalCount - mintData.remaining} of {mintData.totalCount}{' '}
            Minted
          </>
        )}
      </Box>
      <NavMenu
        isMobileMenu={false}
        showMobileMenu={showMobileMenu}
        setShowMobileMenu={setShowMobileMenu}
        availableEth={availableEth}
        availableReg={availableReg}
        auctionPaused={auctionPaused}
      />
      <Box
        sx={{
          display: 'flex',
          gap: isMobile ? '0px' : '20px',
          alignItems: 'center',
        }}
      >
        {account && (
          <Box
            sx={{
              fontWeight: 700,
              textAlign: 'center',
            }}
            // onClick={() => navigate('/wallet')}
          >
            {sort0xAddress(account)}
          </Box>
        )}

        {!account && <ConnectButton onClick={activateBrowserWallet} />}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headerMenu: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    marginInline: '10px',
    marginBottom: '0px',
    alignItems: 'center',
    [theme.breakpoints.down(500)]: {
      padding: theme.spacing(1),
      marginInline: '5px',
    },
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
  mobileNav: {
    fontWeight: 700,
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
}));

export default HomeHeader;
