import React, { useState } from 'react';
import CssBaseLine from '@mui/material/CssBaseline';
import { DAppProvider } from '@usedapp/core';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './index.css';
import Home from './routes/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TopBanner from './components/TopBanner';
import config from './web3/config';
import Header from './components/Header';
import getDesignTokens from './theme/theme';
import { ColorModeContext } from './context/ColorModeContext';
import Footer from 'components/Footer';
import { Box } from '@mui/system';
import Unwrap from 'routes/Unwrap';
import Candy from 'routes/Candy';
import About from 'routes/About';
import Auction from 'routes/Auction';
import MintETH from 'routes/MintEth';
import MintReg from 'routes/MintReg';
import ClaimGift from 'routes/ClaimGift';

const App = () => {
  const storedMode: string = localStorage.getItem('muiMode') || 'dark';
  const [mode, setMode] = React.useState<'light' | 'dark'>('dark');
  const [availableReg, setAvailableReg] = useState();
  const [availableEth, setAvailableEth] = useState();
  const colorMode = React.useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );
  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  const [mintData, setMintData] = useState({});

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseLine />
        <DAppProvider config={config}>
          <BrowserRouter>
            <Box
              display="flex"
              flexDirection="column"
              minHeight="100vh"
              justifyContent="space-between"
              padding="0 10px"
            >
              {/* <TopBanner /> */}
              <Header
                setMintData={setMintData}
                mintData={mintData}
                setAvailableReg={setAvailableReg}
                availableReg={availableReg}
                availableEth={availableEth}
                setAvailableEth={setAvailableEth}
              />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/unwrap" element={<Unwrap />} />
                <Route path="/candy/:id" element={<Candy />} />
                <Route path="/about" element={<About mintData={mintData} />} />
                <Route path="/auction" element={<Auction />} />
                <Route path="/claim-gift" element={<ClaimGift />} />
                <Route
                  path="/mint-eth"
                  element={
                    <MintETH
                      available={availableEth}
                      setAvailable={setAvailableEth}
                    />
                  }
                />
                <Route
                  path="/mint-reg"
                  element={
                    <MintReg
                      setAvailable={setAvailableReg}
                      available={availableReg}
                    />
                  }
                />
              </Routes>
              <Footer />
            </Box>
          </BrowserRouter>
        </DAppProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
