import { Mainnet, Goerli } from '@usedapp/core';
import { getDefaultProvider } from 'ethers';
import CANDIES_ABI from './abi/Candies.json';
import CANDIES_SHOP_ABI from './abi/CandiesShop.json';
import CANDIES_AUCTION_ABI from './abi/CandiesAuction.json';
import DOLLARS_ABI from './abi/RegularDollars.json';

const infuraId = process.env.REACT_APP_INFURA_ID;
export const CHAIN_ID = Number(process.env.REACT_APP_CHAIN_ID) || 5;

const config = {
  readOnlyChainId: CHAIN_ID,
  readOnlyUrls: {
    [Mainnet.chainId]: infuraId
      ? 'https://mainnet.infura.io/v3/' + infuraId
      : getDefaultProvider('mainnet'),
    // [Goerli.chainId]: infuraId
    //   ? 'https://goerli.infura.io/v3/' + infuraId
    //   : getDefaultProvider('goerli'),
    refresh: 20,
  },
};

export const REGULARS_CONTRACT_ADDRESS =
  process.env.REACT_APP_REGULARS_CONTRACT_ADDRESS || '';
export const S3_IMAGES_JPG_URL = process.env.REACT_APP_S3_IMAGES_JPG_URL || '';
export const JOB_API_URL = process.env.REACT_APP_REACT_APP_JOB_API_URL || '';
export const REGULAR_JOBS_ADDRESS =
  process.env.REACT_APP_REGULAR_JOBS_ADDRESS || '';
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
export const COMPANIES_CONTRACT_ADDRESS =
  process.env.REACT_APP_COMPANIES_CONTRACT_ADDRESS || '';
export const REGULAR_NAMES_ADDRESS =
  process.env.REACT_APP_REGULAR_NAMES_ADDRESS || '';
export const REGULAR_DOLLARS_ADDRESS =
  process.env.REACT_APP_REGULAR_DOLLARS_ADDRESS || '';
export const CANDIES_ADDRESS =
  process.env.REACT_APP_CANDIES_CONTRACT_ADDRESS || '';
export const CANDIES_SHOP_ADDRESS =
  process.env.REACT_APP_CANDIES_SHOP_ADDRESS || '';
export const CANDIES_AUCTION_ADDRESS =
  process.env.REACT_APP_CANDIES_AUCTION_ADDRESS || '';
export const candiesABI = CANDIES_ABI;
export const candiesShopABI = CANDIES_SHOP_ABI;
export const candiesAuctionABI = CANDIES_AUCTION_ABI;
export const regularDollarsABI = DOLLARS_ABI;

export default config;
