import { Box, Button, Container, Typography } from '@mui/material';
import { useEthers } from '@usedapp/core';
import { MyRegularsContext } from 'context/MyRegularsContext';
import { Contract } from 'ethers';
import { useContext, useState, useEffect, useRef } from 'react';
import { allNFTsByWallet, getErrorMessageMetamask } from 'utils/helper';
import {
  candiesABI,
  candiesShopABI,
  CANDIES_ADDRESS,
  CANDIES_SHOP_ADDRESS,
} from 'web3/config';

const Unwrap = () => {
  const { account, library } = useEthers();
  const videoRef = useRef<any>();
  const [mintedCandies, setMintedCandies] = useState<any>([]);
  const [activeCandy, setActiveCandy] = useState(0);
  const [metadata, setMetadata] = useState<any>({});
  const [showImage, setShowImage] = useState(false);

  const getWalletNfts = () => {
    if (account) {
      allNFTsByWallet(account, CANDIES_ADDRESS).then(data =>
        setMintedCandies(data)
      );
    }
  };

  const getMetadata = async (tokenId: number) => {
    const contract = new Contract(CANDIES_ADDRESS, candiesABI, library);

    await contract
      .getMetadataJson(tokenId)
      .then((data: any) => {
        setMetadata(JSON.parse(data));
      })
      .catch((e: any) => console.log({ e }));
    setShowImage(true);
  };

  useEffect(() => {
    getWalletNfts();
  }, [account]);

  useEffect(() => {
    setShowImage(false);
    getMetadata(mintedCandies[activeCandy]);
  }, [activeCandy, mintedCandies]);

  const handleUnwrap = async () => {
    if (!account) return;
    const contract = new Contract(
      CANDIES_SHOP_ADDRESS,
      candiesShopABI,
      library?.getSigner()
    );
    const tx = contract
      .unwrap(mintedCandies[activeCandy], account)
      .catch((e: any) => alert(getErrorMessageMetamask(e)));
    await tx.wait();
  };

  useEffect(() => {
    setTimeout(() => videoRef?.current?.load(), 500);
  }, [activeCandy]);

  return (
    <Box
      flexGrow={1}
      display="flex"
      justifyContent={'center'}
      alignItems={'center'}
      paddingX={2}
    >
      <Container
        sx={{
          padding: '10px !important',
          textAlign: 'center',
          width: '100%',
        }}
      >
        {metadata?.attributes && (
          <>
            <Box
              my={2}
              sx={{
                width: '100%',
                position: 'relative',
                background: 'black',
              }}
            >
              <Box
                textAlign="center"
                sx={{
                  background: 'black',
                  width: '100%',
                }}
              >
                <video
                  ref={videoRef}
                  muted
                  loop
                  poster={`https://static.mustardlabs.io/candies/${
                    metadata?.attributes &&
                    metadata?.attributes[1]?.value === 'Unwrapped'
                      ? 'unwrapped'
                      : 'wrapped'
                  }/${mintedCandies[activeCandy]}.jpg`}
                  autoPlay
                  style={{
                    width: '100%',
                    aspectRatio: '1:1',
                    maxHeight: 'calc(80vh - 258px)',
                  }}
                >
                  <source
                    src={`https://static.mustardlabs.io/candies/${
                      metadata?.attributes &&
                      metadata?.attributes[1]?.value === 'Unwrapped'
                        ? 'unwrapped'
                        : 'wrapped'
                    }/${mintedCandies[activeCandy]}.mp4`}
                    type="video/mp4"
                  />
                </video>
              </Box>
            </Box>
            <Box
              sx={{
                justifyContent: 'center',
                display: 'flex',
                gap: '20px',
                marginBottom: '20px',
              }}
            >
              <Button
                variant="outlined"
                style={{
                  opacity: activeCandy < mintedCandies.length - 1 ? 1 : 0,
                  color: 'white',
                  padding: 0,
                  minWidth: '24px',
                  width: '32px',
                  height: '32px',
                  fontSize: '18px',
                }}
                onClick={() => {
                  if (activeCandy < mintedCandies.length - 1) {
                    setActiveCandy(prevState => prevState + 1);
                  }
                }}
              >{`<`}</Button>
              <Box>
                <a
                  style={{
                    color: 'white',
                    textDecoration: 'none',
                    borderBottom: '2px solid',
                  }}
                  href={`https://opensea.io/assets/ethereum/0x832de117d8fa309b55f9c187475a17b87b9dfc85/${mintedCandies[activeCandy]}`}
                >
                  Candy #{mintedCandies[activeCandy]}
                </a>
              </Box>
              <Button
                variant="outlined"
                style={{
                  opacity: activeCandy > 0 ? 1 : 0,
                  color: 'white',
                  padding: 0,
                  minWidth: '24px',
                  width: '32px',
                  height: '32px',
                  fontSize: '18px',
                }}
                onClick={() => {
                  if (activeCandy > 0) {
                    setActiveCandy(prevState => prevState - 1);
                  }
                }}
              >
                {`>`}
              </Button>
            </Box>
            <Button
              onClick={() => handleUnwrap()}
              variant="contained"
              disabled={
                !account ||
                !showImage ||
                (metadata?.attributes &&
                  metadata?.attributes[1]?.value === 'Unwrapped')
              }
            >
              Unwrap
            </Button>
          </>
        )}
        {!metadata.attributes && <Box>You don&apos;t own any candy</Box>}
      </Container>
    </Box>
  );
};

export default Unwrap;
