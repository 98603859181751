import { Box } from '@mui/material';
import GrannyImg from '../media/granny.png';

const About = ({ mintData }: any) => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      gap="20px"
      alignItems="center"
    >
      <Box>
        <img src={GrannyImg} alt="Granny" />
      </Box>
      <Box sx={{ fontSize: '14px' }}>
        <p>Grandma&apos;s Candies is a small collection of regular candies.</p>
        <p>Candies can be unwrapped.</p>
        {mintData && mintData.totalCount && (
          <p>
            {mintData.totalCount - mintData.remaining} of {mintData.totalCount}{' '}
            minted. One mint per wallet.
          </p>
        )}
        <p>Created by @p0pps for Mustard Labs.</p>
      </Box>
    </Box>
  );
};

export default About;
