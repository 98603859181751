import { NavLink } from 'react-router-dom';
import { Box, Theme, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useEthers } from '@usedapp/core';
import { ReactComponent as OpenSeaIcon } from '../media/opensea_logo.svg';
import { ReactComponent as EtherscanIcon } from '../media/etherscan_logo.svg';
import { ReactComponent as DiscordIcon } from '../media/discord.svg';
import { Menu } from '@mui/icons-material';

const NavMenu = ({
  availableEth,
  availableReg,
  auctionPaused,
  setShowMobileMenu,
  showMobileMenu,
  isMobileMenu,
}: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up(930));
  const isSmallScreen = useMediaQuery(theme.breakpoints.up(840));

  if (!isSmallScreen && !isMobileMenu) {
    return (
      <Box
        className={classes.navItems}
        style={{
          justifyContent: 'start',
          flexGrow: 1,
          cursor: 'pointer',
        }}
        onClick={() => setShowMobileMenu(!showMobileMenu)}
      >
        <Menu />
      </Box>
    );
  }
  return (
    <Box
      className={classes.navItems}
      style={{
        gap: !isBigScreen ? '12px' : '40px',
        alignItems: 'center',
        flexWrap: isBigScreen ? 'nowrap' : 'wrap',
      }}
    >
      <NavLink to="/" onClick={() => setShowMobileMenu(false)}>
        <Box
          style={{
            fontWeight: 400,
            fontSize: '16px',
            borderBottom:
              window.location.pathname === '/' ? '2px solid' : 'none',
          }}
        >
          Home
        </Box>
      </NavLink>
      <NavLink to="/about" onClick={() => setShowMobileMenu(false)}>
        <Box
          style={{
            fontWeight: 400,
            fontSize: '16px',
            borderBottom:
              window.location.pathname === '/about' ? '2px solid' : 'none',
          }}
        >
          About
        </Box>
      </NavLink>
      <NavLink
        to="/mint-eth"
        onClick={() => setShowMobileMenu(false)}
        style={{
          opacity: !Number(availableEth) ? 0.4 : 1,
          pointerEvents: !Number(availableEth) ? 'none' : 'all',
        }}
      >
        <Box
          style={{
            fontWeight: 400,
            fontSize: '16px',
            borderBottom:
              window.location.pathname === '/mint-eth' ? '2px solid' : 'none',
          }}
        >
          Mint (ETH)
        </Box>
      </NavLink>
      <NavLink
        onClick={() => setShowMobileMenu(false)}
        to="/mint-reg"
        style={{
          opacity: !Number(availableReg) ? 0.4 : 1,
          pointerEvents: !Number(availableReg) ? 'none' : 'all',
        }}
      >
        <Box
          style={{
            fontWeight: 400,
            fontSize: '16px',
            borderBottom:
              window.location.pathname === '/mint-reg' ? '2px solid' : 'none',
          }}
        >
          Mint (REG)
        </Box>
      </NavLink>
      <NavLink
        onClick={() => setShowMobileMenu(false)}
        to="/auction"
        style={{
          opacity: auctionPaused ? 0.4 : 1,
          pointerEvents: auctionPaused ? 'none' : 'all',
        }}
      >
        <Box
          style={{
            fontWeight: 400,
            fontSize: '16px',
            borderBottom:
              window.location.pathname === '/auction' ? '2px solid' : 'none',
          }}
        >
          Auction
        </Box>
      </NavLink>
      <NavLink onClick={() => setShowMobileMenu(false)} to="/unwrap">
        <Box
          style={{
            fontWeight: 400,
            fontSize: '16px',
            borderBottom:
              window.location.pathname === '/unwrap' ? '2px solid' : 'none',
          }}
        >
          Unwrap
        </Box>
      </NavLink>
      <a
        href="https://opensea.io/collection/grandmas-candies"
        target="new"
        onClick={() => setShowMobileMenu(false)}
      >
        <Box>
          <OpenSeaIcon style={{ marginTop: '2px' }} />
        </Box>
      </a>
      <a
        href="https://etherscan.io/address/0x832DE117D8fA309B55F9C187475a17B87b9dFc85"
        target="new"
      >
        <Box>
          <EtherscanIcon style={{ marginTop: '2px' }} />
        </Box>
      </a>
      <a
        href="https://discord.gg/6uj7AFE5"
        target="new"
        onClick={() => setShowMobileMenu(false)}
      >
        <Box
          sx={{
            width: '18px',
            height: '18px',
            borderRadius: '50px',
            background: 'white',
            position: 'relative',
            '& svg': {
              position: 'absolute',
              top: '2px',
              left: '2px',
            },
          }}
        >
          <DiscordIcon
            style={{
              fontSize: '10px',
              width: '14px',
              height: '14px',
              fill: 'black',
            }}
          />
        </Box>
      </a>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  navItems: {
    padding: theme.spacing(1),
    display: 'flex',
    fontSize: '1.5rem',
    fontWeight: 400,
    '& a': {
      marginBottom: '4px',
    },
    '& .activeLink': {
      borderBottom: '4px solid',
      marginBottom: 0,
    },
  },
}));

export default NavMenu;
