const getDesignTokens = (mode: 'light' | 'dark') => ({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: '1.4em',
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          outline: '1px solid slategrey',
        },
      },
    },
  },
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          background: {
            default: '#FFFFFF',
          },
          primary: {
            main: '#000000',
          },
          secondary: {
            main: '#FFFFFF',
          },
          success: {
            main: '#19B822',
          },
        }
      : {
          background: {
            default: 'black',
          },
          primary: {
            main: '#FFFFFF',
          },
          secondary: {
            main: '#000000',
          },
          success: {
            main: '#19B822',
          },
        }),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          fontWeight: 700,
          borderRadius: '110px',
          padding: '5px 20px',
        },
        outlined: {
          border: '2px solid',
          '&:hover': {
            border: '2px solid',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: ['Inter'].join(','),
    button: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '20px',
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: '0.5px',
    },
    h5: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.5px',
    },
    h4: {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: '21px',
    },
    h3: {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: '25px',
    },
    h2: {
      fontSize: 12,
      lineHeight: '28px',
      fontWeight: 700,
    },
    h1: {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: '35px',
    },
    smallHeading: {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: '28px',
    },
    paragraph: {
      fontSize: 12,
    },
  },
});

export default getDesignTokens;
