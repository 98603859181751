import React, { useEffect } from 'react';
import { Box, Button, Input, useMediaQuery, useTheme } from '@mui/material';
import { formatDistance } from 'date-fns';
import { useEthers, useLookupAddress } from '@usedapp/core';
import { Contract, ethers } from 'ethers';
import {
  candiesAuctionABI,
  CANDIES_AUCTION_ADDRESS,
  regularDollarsABI,
  REGULAR_DOLLARS_ADDRESS,
} from 'web3/config';
import { getErrorMessageMetamask, sort0xAddress } from 'utils/helper';
const Auction = () => {
  const { library, account } = useEthers();
  const [endTime, setEndTime] = React.useState(0);
  const [isEnded, setIsEnded] = React.useState(false);

  const [highBidders, setHighBidders] = React.useState<any>([]);
  const getHighBidders = () => {
    const contractInstance = new Contract(
      CANDIES_AUCTION_ADDRESS,
      candiesAuctionABI,
      library
    );
    contractInstance.allHighBidders().then((data: any) => {
      setHighBidders([...data]);
    });
  };

  const getTime = () => {
    const contractInstance = new Contract(
      CANDIES_AUCTION_ADDRESS,
      candiesAuctionABI,
      library
    );
    contractInstance.startTime().then((data: any) => {
      contractInstance.duration().then((dur: any) => {
        setEndTime(Number(data) + Number(dur));
      });
    });
  };

  const getIsEnded = () => {
    const contractInstance = new Contract(
      CANDIES_AUCTION_ADDRESS,
      candiesAuctionABI,
      library
    );
    contractInstance.ended().then((data: any) => {
      setIsEnded(Boolean(data));
    });
  };

  useEffect(() => {
    getHighBidders();
    getTime();
    getIsEnded();
  }, []);
  const [price, setPrice] = React.useState();

  const handleBid = () => {
    const contractInstance = new Contract(
      REGULAR_DOLLARS_ADDRESS,
      regularDollarsABI,
      library?.getSigner()
    );
    if (price) {
      contractInstance
        .transferAndCall(
          CANDIES_AUCTION_ADDRESS,
          ethers.utils.parseUnits(price, 18)
        )
        .catch((e: any) => alert(getErrorMessageMetamask(e)));
    }
  };

  const handleClaim = () => {
    const contractInstance = new Contract(
      CANDIES_AUCTION_ADDRESS,
      candiesAuctionABI,
      library?.getSigner()
    );
    contractInstance
      .claim(account)
      .catch((e: any) => alert(getErrorMessageMetamask(e)));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <p style={{ marginBottom: 0, textAlign: 'center' }}>
        The 10 highest bidders can mint candies.
      </p>
      {!!endTime && !isEnded && (
        <p>
          Auction ends in{' '}
          {formatDistance(new Date(), new Date(endTime * 1000), {
            includeSeconds: true,
          })}
          .
        </p>
      )}
      {isEnded && <p>Auction is over.</p>}
      <Box>
        <Box
          sx={{
            display: 'flex',
            gap: '30px',
            marginBottom: '10px',
            opacity: 0.3,
            flexWrap: 'wrap',
          }}
        >
          <Box sx={{ textDecoration: 'underline', width: '80px' }}>Bids</Box>
          <Box sx={{ textDecoration: 'underline' }}>Wallet</Box>
        </Box>
        {highBidders.length > 0 &&
          highBidders
            .sort((a: any, b: any) => {
              return (
                Number(ethers.utils.formatEther(b[1])) -
                Number(ethers.utils.formatEther(a[1]))
              );
            })
            .map((item: any, index: number) => (
              <Box
                style={{
                  display: 'flex',
                  marginBottom: '10px',
                  gap: '30px',
                }}
                key={index}
              >
                <Box sx={{ width: '80px', color: 'white' }}>
                  Ⓡ{Number(ethers.utils.formatEther(item[1])).toFixed(0)}
                </Box>
                <Box
                  sx={{
                    opacity: account === item[0] ? 1 : 0.3,
                  }}
                >
                  {item[0] && <DisplayWallet wallet={item[0]} />}
                </Box>
              </Box>
            ))}
      </Box>
      <Box
        sx={{
          padding: '30px',
          border: '1px solid rgba(255,255,255,0.3)',
          borderRadius: '30px',
          marginTop: '40px;',
        }}
      >
        {!!endTime && !isEnded && (
          <>
            <Box
              display="flex"
              mb="20px"
              gap="20px"
              justifyContent="center"
              alignItems="center"
            >
              <div>Submit Bid</div>
              <div>
                <Input
                  value={price}
                  onChange={(e: any) => setPrice(e.target.value)}
                  placeholder="REG"
                  sx={{ width: '120px' }}
                />
              </div>
              <Button variant="contained" onClick={handleBid}>
                Submit
              </Button>
            </Box>

            <Box
              sx={{
                opacity: 0.3,
                textAlign: 'center',
              }}
            >
              If you get bumped off the list, your bid amount will be refunded.
              <br />
              To increase your bid, submit the total, the difference will be
              refunded.
              <br />
              <br />
              <a
                href="https://etherscan.io/address/0x78b5C6149C87c82EDCffC73C230395abbc56DdD5"
                target="_new"
                style={{
                  color: 'white',
                  textDecoration: 'none',
                  borderBottom: '2px solid',
                }}
              >
                Link
              </a>{' '}
              to contract
            </Box>
          </>
        )}
        {isEnded && (
          <>
            <Box gap="20px" display="flex" mb="20px">
              {highBidders.findIndex(
                (item: any) => item[0].toLowerCase() === account?.toLowerCase()
              ) > -1 ? (
                <>
                  <Box>You won!</Box>
                  <Button variant="contained" onClick={() => handleClaim()}>
                    CLAIM NOW
                  </Button>
                </>
              ) : (
                <>You haven&apos;t won, or already claimed.</>
              )}
            </Box>
            <Box
              sx={{
                marginTop: '20px',
                opacity: 0.3,
                textAlign: 'center',
              }}
            >
              <a
                href="https://etherscan.io/address/0x78b5C6149C87c82EDCffC73C230395abbc56DdD5"
                target="_new"
                style={{
                  color: 'white',
                  textDecoration: 'none',
                  borderBottom: '2px solid',
                }}
              >
                Link
              </a>{' '}
              to contract
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

const DisplayWallet = ({ wallet }: any) => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up(560));
  const { ens } = useLookupAddress(wallet);
  return <>{ens || (!isBigScreen ? sort0xAddress(wallet) : wallet)}</>;
};

export default Auction;
