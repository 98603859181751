import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { useEthers } from '@usedapp/core';
import { Contract, ethers } from 'ethers';
import { candiesShopABI, CANDIES_SHOP_ADDRESS } from 'web3/config';
import { getErrorMessageMetamask } from 'utils/helper';

const MintETH = ({ available, setAvailable }: any) => {
  const { library, account } = useEthers();
  const [price, setPrice] = useState('0');

  const getAvailable = () => {
    const contractInstance = new Contract(
      CANDIES_SHOP_ADDRESS,
      candiesShopABI,
      library
    );
    contractInstance
      .availableETH()
      .then((data: any) => {
        console.log(data);
        setAvailable(Number(data).toString());
      })
      .catch((e: any) => console.log(e));
  };
  const mintWithEth = () => {
    const contractInstance = new Contract(
      CANDIES_SHOP_ADDRESS,
      candiesShopABI,
      library?.getSigner()
    );
    contractInstance
      .mintWithEth(account, {
        value: price,
      })
      .catch((e: any) => alert(getErrorMessageMetamask(e)));
  };

  const getPrice = () => {
    const contractInstance = new Contract(
      CANDIES_SHOP_ADDRESS,
      candiesShopABI,
      library
    );
    contractInstance.getPriceETH().then((data: any) => setPrice(data));
  };

  useEffect(() => {
    getPrice();
    getAvailable();
  }, [library]);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div>Available: {available}</div>
      <div>
        Price:{' '}
        {ethers.utils
          .formatEther(price)
          .toString()
          .slice(1, price.toString().length)}{' '}
        ETH
      </div>
      <Button
        variant="contained"
        onClick={() => {
          mintWithEth();
        }}
        disabled={Number(available) === 0}
      >
        mint
      </Button>
    </Box>
  );
};

export default MintETH;
