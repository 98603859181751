import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { useEthers } from '@usedapp/core';
import { Contract, ethers } from 'ethers';
import { candiesShopABI, CANDIES_SHOP_ADDRESS } from 'web3/config';
import { getErrorMessageMetamask } from 'utils/helper';

const ClaimGift = () => {
  const { library, account } = useEthers();
  const [available, setAvailable] = useState();

  const getAvailable = () => {
    const contractInstance = new Contract(
      CANDIES_SHOP_ADDRESS,
      candiesShopABI,
      library
    );
    contractInstance
      .hasGift(account)
      .then((data: any) => {
        setAvailable(data);
      })
      .catch((e: any) => console.log(e));
  };
  const claimGift = () => {
    if (!account) return;
    const contractInstance = new Contract(
      CANDIES_SHOP_ADDRESS,
      candiesShopABI,
      library?.getSigner()
    );
    contractInstance
      .claimGift(account)
      .catch((e: any) => alert(getErrorMessageMetamask(e)));
  };

  useEffect(() => {
    getAvailable();
  }, [library]);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div>
        {available && <>You can claim your gift candy</>}
        {!available && <>You have no gifts to claim.</>}
      </div>
      <Button
        variant="contained"
        onClick={() => {
          claimGift();
        }}
        disabled={!available || !account}
      >
        claim
      </Button>
    </Box>
  );
};

export default ClaimGift;
