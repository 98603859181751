import React from 'react';
import { useEthers } from '@usedapp/core';

import HomeHeader from './HomeHeader';
import { Contract } from 'ethers';
import {
  candiesABI,
  candiesShopABI,
  CANDIES_ADDRESS,
  CANDIES_AUCTION_ADDRESS,
  CANDIES_SHOP_ADDRESS,
} from 'web3/config';

const Header: React.FC<{
  mintData: any;
  setMintData: any;
  availableEth: any;
  setAvailableEth: any;
  availableReg: any;
  setAvailableReg: any;
}> = ({
  mintData,
  setMintData,
  availableEth,
  setAvailableEth,
  availableReg,
  setAvailableReg,
}: any) => {
  const { activateBrowserWallet, account, library } = useEthers();
  const [available, setAvailable] = React.useState(0);
  const [auctionPaused, setAuctionPaused] = React.useState<any>(true);

  const getAvailableReg = () => {
    const contractInstance = new Contract(
      CANDIES_SHOP_ADDRESS,
      candiesShopABI,
      library
    );
    contractInstance
      .availableREG()
      .then((data: any) => {
        console.log(data);
        setAvailableReg(Number(data));
      })
      .catch((e: any) => console.log(e));
  };
  const getAvailableEth = () => {
    const contractInstance = new Contract(
      CANDIES_SHOP_ADDRESS,
      candiesShopABI,
      library
    );
    contractInstance
      .availableETH()
      .then((data: any) => {
        console.log(data);
        setAvailableEth(Number(data).toString());
      })
      .catch((e: any) => console.log(e));
  };

  const getMintData = async () => {
    const contract = new Contract(CANDIES_ADDRESS, candiesABI, library);

    const totalCount = await contract
      .totalCount()
      .catch((e: any) => console.log(e));
    const remaining = await contract.remaining();
    setAvailable(Number(totalCount) - Number(remaining));
    setMintData({
      totalCount: totalCount ? Number(totalCount) : 0,
      remaining: remaining ? Number(remaining) : 0,
      available: Number(totalCount || 0) - Number(remaining || 0),
    });
  };

  const getAuctionPaused = () => {
    const contractInstance = new Contract(
      CANDIES_AUCTION_ADDRESS,
      candiesShopABI,
      library
    );
    contractInstance
      .paused()
      .then((data: any) => {
        console.log(data);
        setAuctionPaused(Boolean(data));
      })
      .catch((e: any) => console.log(e));
  };

  React.useEffect(() => {
    getMintData();
    getAvailableReg();
    getAvailableEth();
    getAuctionPaused();
  }, []);
  console.log(CANDIES_ADDRESS);
  return (
    <HomeHeader
      account={account}
      activateBrowserWallet={activateBrowserWallet}
      mintData={mintData}
      availableReg={availableReg}
      availableEth={availableEth}
      auctionPaused={auctionPaused}
    />
  );
};

export default Header;
