import { Box } from '@mui/material';
import { useEthers, useLookupAddress } from '@usedapp/core';
import { formatDistance } from 'date-fns';
import { Contract } from 'ethers';
import { useEffect, useState, useRef } from 'react';
import { decodeEvents, getEvents, sort0xAddress } from 'utils/helper';
import { candiesABI, CANDIES_ADDRESS } from 'web3/config';

const Home = () => {
  const { library } = useEthers();
  const [events, setEvents] = useState<any>([]);
  const [activeCandy, setActiveCandy] = useState<any>({});
  const [eventEns, setEventEns] = useState<any>('');
  const videoRef = useRef<any>();
  const random = Math.floor(Math.random() * 220);

  const retrieveEvents = async () => {
    const tmp = await getEvents();
    const decode = decodeEvents(tmp)
      .filter((item: any) => item.isMint || item.isUnwrap)
      .sort((a: any, b: any) => b.blockNumber - a.blockNumber);
    setEvents(decode);
  };
  useEffect(() => {
    retrieveEvents();
  }, []);

  const [activeEvent, setActiveEvent] = useState(0);
  const [dateText, setDateText] = useState<any>();

  const done = async () => {
    const block = await library?.getBlock(events[activeEvent].blockNumber);
    if (block?.timestamp) {
      setDateText(
        formatDistance(new Date(block?.timestamp * 1000), new Date(), {
          addSuffix: true,
        })
      );
    }
  };

  const [metadata, setMetadata] = useState<any>();

  const getMetadata = async (tokenId: number) => {
    const contract = new Contract(CANDIES_ADDRESS, candiesABI, library);
    await contract.getMetadataJson(tokenId).then((data: any) => {
      setMetadata(JSON.parse(data));
    });
  };

  useEffect(() => {
    if (events.length > 0) {
      done();
      setActiveCandy(events[activeEvent]);
      getMetadata(events[activeEvent].candyId);
      videoRef.current?.load();
      try {
        const { ens } = useLookupAddress(events[activeEvent].wallet);
        setEventEns(ens);
      } catch (e) {
        console.log(e);
      }
    }
  }, [activeEvent, events]);

  useEffect(() => {
    videoRef.current?.load();
  }, [metadata]);

  return (
    <Box textAlign="center" style={{ position: 'relative' }}>
      {events.length === 0 || !metadata ? (
        <video
          muted
          loop
          poster={`https://static.mustardlabs.io/candies/wrapped/${random}.jpg`}
          autoPlay
          style={{
            aspectRatio: '1:1',
            maxHeight: 'calc(80vh - 178px)',
            width: '100%',
          }}
        >
          <source
            src={`https://static.mustardlabs.io/candies/wrapped/${random}.mp4`}
            type="video/mp4"
          />
        </video>
      ) : (
        <video
          ref={videoRef}
          muted
          loop
          poster={
            activeCandy.isMint
              ? `https://static.mustardlabs.io/candies/wrapped/${activeCandy.candyId}.jpg`
              : metadata.image
          }
          autoPlay
          style={{
            aspectRatio: '1:1',
            maxHeight: 'calc(80vh - 178px)',
            width: '100%',
          }}
        >
          <source
            src={
              activeCandy.isMint
                ? `https://static.mustardlabs.io/candies/wrapped/${activeCandy.candyId}.mp4`
                : metadata.mp4
            }
            type="video/mp4"
          />
        </video>
      )}
      {events.length > 0 && (
        <>
          <Box
            style={{
              position: 'absolute',
              bottom: '15px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <EventPrinter
              candyId={activeCandy.candyId}
              event={activeCandy}
              dateText={dateText}
            />
            <br />
            <Box
              style={{
                marginInline: 'auto',
                display: 'flex',
                justifyContent: 'center',
                maxWidth: '200px',
                width: '100%',
                gap: '15px',
                marginTop: '20px',
              }}
            >
              <button
                style={{
                  background: 'none',
                  color: 'white',
                  border: '2px solid white',
                  borderRadius: '50px',
                  fontSize: '14px',
                  padding: '2px 7px',
                  fontWeight: 800,
                  opacity: activeEvent < events.length - 1 ? 1 : 0.3,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setEventEns('');
                  setActiveEvent((prev: number) => prev + 1);
                }}
                disabled={activeEvent === events.length - 1}
              >
                {'<'}
              </button>
              <button
                style={{
                  background: 'none',
                  color: 'white',
                  border: '2px solid white',
                  borderRadius: '50px',
                  fontSize: '14px',
                  padding: '2px 7px',
                  fontWeight: 800,

                  opacity: activeEvent > 0 ? 1 : 0.3,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setEventEns('');
                  setActiveEvent((prev: number) => prev - 1);
                }}
                disabled={activeEvent === 0}
              >
                {'>'}
              </button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

const EventPrinter = ({ candyId, event, dateText }: any) => {
  const { ens } = useLookupAddress(event.wallet);

  return (
    <>
      <a
        href={`https://opensea.io/assets/ethereum/0x832de117d8fa309b55f9c187475a17b87b9dfc85/${candyId}`}
        target="_new"
        style={{
          color: 'white',
          textDecoration: 'none',
          borderBottom: '2px solid white',
        }}
      >
        Candy #{candyId}
      </a>{' '}
      {event.isMint ? 'was minted' : 'was unwrapped'} {dateText} by{' '}
      {ens || sort0xAddress(event.wallet)}
    </>
  );
};

export default Home;
