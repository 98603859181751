import { ethers } from 'ethers';
import { GET_REGULAR_API_URL } from './constants';
import { createAlchemyWeb3 } from '@alch/alchemy-web3';
import {
  CANDIES_ADDRESS,
  CANDIES_AUCTION_ADDRESS,
  CANDIES_SHOP_ADDRESS,
  CHAIN_ID,
} from 'web3/config';
import { Network, Alchemy } from 'alchemy-sdk';

interface FiltersType {
  [key: string]: string;
}

export const isDarkMode = () => {
  if (localStorage.getItem('muiMode'))
    return localStorage.getItem('muiMode') !== 'light';
  return false;
};

export const sort0xAddress = (address: string) => {
  if (!address) return address;
  console.log(address);
  return `${address.slice(0, 4)}...${address.slice(
    address.length - 3,
    address.length
  )}`;
};

const encodeQueryData = (data: FiltersType) => {
  const ret = [];
  for (let d in data) ret.push(d + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
};

export const getRegularNFTs = (filters: FiltersType, cursor?: number) => {
  let modFilters: any = {};
  Object.keys(filters).forEach((key: string) => {
    modFilters[key.toLowerCase().split(' ').join('')] = filters[key];
  });

  const fl = encodeQueryData(modFilters);
  return fetch(
    GET_REGULAR_API_URL + '/get-regulars?cursor=' + (cursor || 0) + '&' + fl
  );
};

const alcheUrl =
  CHAIN_ID === 1
    ? 'https://eth-mainnet.g.alchemy.com/v2/C3TQE3JEcky_r8zfz0y08_YQShTY4y7P'
    : 'https://eth-goerli.g.alchemy.com/v2/H-sX5kEtCodlE_aVFwr6ZKW9vMANyAWU';
// Using HTTPS
const web3 = createAlchemyWeb3(alcheUrl);
export const allNFTsByWallet = async (
  account: string,
  contractAddress: string
) => {
  const nftsForOwner =
    (
      await web3.alchemy.getNfts({
        owner: account,
        contractAddresses: [contractAddress],
      })
    ).ownedNfts || [];

  const nftIds = nftsForOwner.map((item: any) => {
    const number = ethers.BigNumber.from(item.id.tokenId).toNumber();
    return number;
  });

  return nftIds;
};

const settings =
  CHAIN_ID === 1
    ? {
        apiKey: 'C3TQE3JEcky_r8zfz0y08_YQShTY4y7P',
        network: Network.ETH_MAINNET,
      }
    : {
        apiKey: 'H-sX5kEtCodlE_aVFwr6ZKW9vMANyAWU',
        network: Network.ETH_GOERLI,
      };
const alchemy = new Alchemy(settings);

export const getEvents = async () => {
  const events = await alchemy.core.getLogs({
    address: CANDIES_ADDRESS,
    fromBlock: 'earliest',
  });
  return events.filter(
    it =>
      it.topics.includes(
        '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef'
      ) ||
      it.topics.includes(
        '0xaec8e13f64207044c453b2a37afb225aee4220504c856bb67504075327026c08'
      )
  );
};

export const decodeEvents = (events: any) => {
  return events.map((event: any) => {
    //mint
    if (
      event.topics[0] ===
      '0xaec8e13f64207044c453b2a37afb225aee4220504c856bb67504075327026c08'
    ) {
      const wallet = ethers.utils.defaultAbiCoder.decode(
        ['address'],
        event.topics[1]
      )[0];
      console.log(wallet);
      const candyId = Number(
        ethers.utils.defaultAbiCoder.decode(
          ['uint256', 'uint256'],
          event.data
        )[0]
      );
      return { ...event, wallet, candyId, isUnwrap: true };
    }
    if (
      event.topics[0] ===
      '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef'
    ) {
      const isMint =
        event.topics[1] ===
        '0x0000000000000000000000000000000000000000000000000000000000000000';
      const wallet = ethers.utils.defaultAbiCoder.decode(
        ['address'],
        event.topics[2]
      )[0];
      const candyId = Number(
        ethers.utils.defaultAbiCoder.decode(['uint256'], event.topics[3])[0]
      );
      return { ...event, wallet, candyId, isMint };
    }
    return event;
  });
};

export const getErrorMessageMetamask = (e: any) => {
  if (e) {
    if (JSON.stringify(e).split('message":"')[1]) {
      return (
        JSON.stringify(e).split('message":"')[1].split('"')[0] +
        `
      CandyContract: ${CANDIES_ADDRESS}.
      CandyShop: ${CANDIES_SHOP_ADDRESS}.
      CandyAuction: ${CANDIES_AUCTION_ADDRESS}
      `
      );
    }
    if (JSON.stringify(e).split('reason":"')[1]) {
      return (
        JSON.stringify(e).split('reason":"')[1].split('"')[0] +
        `
      CandyContract: ${CANDIES_ADDRESS}.
      CandyShop: ${CANDIES_SHOP_ADDRESS}.
      CandyAuction: ${CANDIES_AUCTION_ADDRESS}
      `
      );
    }
  }
  return (
    e +
    `
  CandyContract: ${CANDIES_ADDRESS}.
  CandyShop: ${CANDIES_SHOP_ADDRESS}.
  CandyAuction: ${CANDIES_AUCTION_ADDRESS}
  `
  );
};
