import { Box, Button, Container, Typography } from '@mui/material';
import { useEthers } from '@usedapp/core';
import { MyRegularsContext } from 'context/MyRegularsContext';
import { Contract } from 'ethers';
import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { candiesABI, CANDIES_ADDRESS } from 'web3/config';

const Candy = () => {
  const { library } = useEthers();
  const params = useParams();
  const [metadata, setMetadata] = useState<any>({});
  const [showImage, setShowImage] = useState(false);

  const getMetadata = async (tokenId: number) => {
    const contract = new Contract(CANDIES_ADDRESS, candiesABI, library);
    await contract.getMetadataJson(tokenId).then((data: any) => {
      setMetadata(JSON.parse(data));
    });
    setShowImage(true);
  };

  useEffect(() => {
    setShowImage(false);
    getMetadata(Number(params?.id || 0));
  }, [library]);

  return (
    <Box
      flexGrow={1}
      display="flex"
      justifyContent={'center'}
      alignItems={'flex-start'}
      paddingX={2}
    >
      <Container
        sx={{
          maxWidth: '376px !important',
          padding: '10px !important',
          textAlign: 'center',
        }}
      >
        <Box>{metadata.name}</Box>
        <Box
          my={2}
          sx={{
            width: '100%',
            maxWidth: '356px',
            position: 'relative',
            background: 'black',
          }}
        >
          <img
            src={metadata.image}
            alt="Candy!"
            style={{
              width: '100%',
              visibility: showImage ? 'visible' : 'hidden',
            }}
          />
        </Box>
        <Box>{metadata.description}</Box>
      </Container>
    </Box>
  );
};

export default Candy;
